import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';

import { LightBox, ImageType } from 'components/LightBox';
interface Props {
    setProfessionalOpen: any;
    setVendorOpen: any;
    setCustomerOpen: any;
}

const PaginationTab = ({ setProfessionalOpen, setVendorOpen, setCustomerOpen }: Props) => {
    const [category, setCategory] = useState<string>('vendor');

    const handleProfesionalClick = () => {
        setProfessionalOpen(true);
        setVendorOpen(false);
        setCustomerOpen(false);
        setCategory('professional');
    };

    const handleVendorClick = () => {
        setProfessionalOpen(false);
        setVendorOpen(true);
        setCustomerOpen(false);
        setCategory('vendor');
    };

    const handleUserClick = () => {
        setProfessionalOpen(false);
        setVendorOpen(false);
        setCustomerOpen(true);
        setCategory('user');
    };

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className="text-center filter-menu">
                        <Link
                            to="#"
                            className={classNames('filter-menu-item', 'me-1', {
                                active: category === 'vendor',
                            })}
                            onClick={handleVendorClick}
                        >
                            Business
                        </Link>
                        <Link
                            to="#"
                            className={classNames('filter-menu-item', 'me-1', {
                                active: category === 'professional',
                            })}
                            onClick={handleProfesionalClick}
                        >
                            Professional
                        </Link>
                        <Link
                            to="#"
                            className={classNames('filter-menu-item', 'me-1', {
                                active: category === 'user',
                            })}
                            onClick={handleUserClick}
                        >
                            User
                        </Link>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default PaginationTab;
